import request from '@/utils/request'

export function getCustomerOaInfo(formFile) {
    return request({
        url: "/api/ajax/customerOa/getCustomerOaInfo.json",
        method: 'post',
        data: formFile
    })
}

export function getSimilarCustomerOaInfo(formFile) {
    return request({
        url: "/api/ajax/customerOa/getSimilarCustomerOaInfo.json",
        method: 'post',
        data: formFile
    })
}

export function deleteOaCustomer(formFile) {
    return request({
        url: "/api/ajax/customerOa/deleteOaCustomer.json",
        method: 'post',
        data: formFile
    })
}