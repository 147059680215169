<template>
  <div class="customer-oa">
    <van-search v-model="customerValue" placeholder="请输入客户关键词"/>
    <div :style="{height: freshHeight+ 20 +'px',overflowY: 'scroll', boxSizing: 'border-box'}">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh"
      >
        <van-list
            v-model="loading"
            loading-text="加载中"
            :finished="finished"
            finished-text="没有更多了"
            @load="nextPage"
        >
          <van-swipe-cell class="swipecell" rightWidth="65" id="myswipe" v-for="item in list">
            <van-cell-group>
              <van-cell :key="item.id" :title="item.cusName" value="操作" :label="'OA生成时间：'+item.oaTime"
                        is-link :to="'CustomerOaDetail/'+item.cusName">
                <!-- 使用 title 插槽来自定义标题 -->
                <template slot="title">
                  <van-row>
                    <el-tooltip class="item" effect="dark" :content="item.cusName" placement="bottom">
                      <van-col offset="0" span="22" class="text van-ellipsis info">
                        {{ item.cusName }}
                      </van-col>
                    </el-tooltip>
                    <van-col offset="1" span="1">
                      <van-tag type="danger">{{ item.pd == '临时拜访' ? '临' : item.pd }}</van-tag>
                    </van-col>
                  </van-row>
                </template>

              </van-cell>
            </van-cell-group>
            <template slot="right">
              <van-button style="height: 100%; width: 100%;" square type="danger"
                          @click="deleteList(item)" text="删除"/>
            </template>
          </van-swipe-cell>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>

</template>

<script>
import {getCustomerOaInfo} from '@/api/customerOa'
import {deleteOaCustomer} from '@/api/customerOa'

export default {
  name: "CustomerOa",
  data() {
    return {
      list: [],
      customerValue: '',
      loading: false,
      finished: false,
      refreshing: false,
      freshHeight: 0,
      currentPage: 1, // 当前页码
      pageSize: 25, // 每页个数
    };
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 70
    this.onRefresh()
  },
  watch: {
    //监听公司名头
    'customerValue': function () {
      this.list = [];
      this.onRefresh();
    },
  },
  methods: {
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }

        for (let i = 0; i < 10; i++) {
          this.list.push('鞍钢股份有限公司');
        }
        this.loading = false;

        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    // 加载下一页
    nextPage() {
      setTimeout(() => {
        if (!this.finished) {
          this.loading = false
          this.currentPage += 1
          this.getData()
        }
      }, 500)
    },
    // 下拉刷新
    onRefresh() {
      this.currentPage = 1
      this.list = []
      this.finished = false
      this.refreshing = true
      this.loading = true
      this.getData()
    },
    // 下拉刷新
    getData() {
      this.loading = true
      getCustomerOaInfo({
        current: this.currentPage,
        size: this.pageSize,
        cusName: this.customerValue
      }).then(res => {
        this.loading = false
        // 如果下拉刷新，那么数据清空重新获取第一页
        if (this.refreshing) {
          this.list = []
          this.refreshing = false
        }
        console.log(res)
        res.forEach(item => {
          this.list.push(item)
        })
        this.listRow = this.list.length
        // 如果数据加载完毕，finished 标志位置为 true，滑到页面底部则不会再触发 nextPage() 方法了。currentPage 重置为 1
        if (res.length < this.pageSize - 1) {
          this.finished = true
        } else {
          this.finished = false
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('查询失败！');
      })
    },
    deleteList(item) {
      console.log(item)
      deleteOaCustomer({
        id: item.id
      }).then(res => {
        this.$message.success('删除成功！');
        this.onRefresh()
      }).catch(err => {
        console.log(err)
        this.$message.error('删除失败！');
      })
    }

  }
}
</script>

<style scoped>
.customer-oa .custom-title {
  text-align: center;
  vertical-align: center;
}

.customer-oa .van-cell {
  touch-action: none;
}

.customer-oa .delete {
  height: 100%;
  text-align: center;
  width: 65px;
  line-height: 45px;
  color: #fff;
  background-color: #ec132d;
}
</style>